import { render, staticRenderFns } from "./logout.vue?vue&type=template&id=419e0621&scoped=true&"
import script from "./logout.vue?vue&type=script&lang=js&"
export * from "./logout.vue?vue&type=script&lang=js&"
import style0 from "./logout.vue?vue&type=style&index=0&id=419e0621&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "419e0621",
  null
  
)

/* custom blocks */
import block0 from "./logout.vue?vue&type=custom&index=0&blockType=route"
if (typeof block0 === 'function') block0(component)

export default component.exports