<template>
  <main></main>
</template>

<route>
{
    "meta": {
        "label": "登出"
    }
}
</route>

<script>
export default {
  name: 'Logout',
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped>
#logo {
  max-width: 240px;
  max-height: 100px;
}
</style>
